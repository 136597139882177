import {useField} from "formik";
import {useEffect, useState} from "react";
import {FunctionComponent} from "react";
import React from "react";
import styled from "styled-components";
import CheckIcon from "../../assets/icons/check.svg";
import FormGroup from "../internals/FormGroup";
import Label from "../internals/Label";


const CheckboxLabel = styled.label`
    position: relative;
    width: 100%;
    display: inline-block;
`;

const Input = styled.input`
    position: absolute;
    opacity: 0;
    visibility: hidden;
    height: 0;
    overflow: hidden;
`;

const Div = styled.div<{ rounded?: boolean }>`
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
    font-size: 12px;
    line-height: 1.4;

    a {
        color: ${props => props.theme.colors.primary};
        text-decoration: underline !important;
    }

    &:before {
        content: '';
        display: block;
        width: 30px;
        height: 30px;
        border: 1px solid ${props => props.rounded ? props.theme.colors.secondary : props.theme.colors.primary};
        border-radius: ${props => props.rounded ? "50%" : "3px"};
    }

    &.checked {
        &:before {
            background-color: ${props => props.rounded ? props.theme.colors.secondary : props.theme.colors.primary};
            background-image: url(${CheckIcon});
            background-repeat: no-repeat;
            background-position: center;
            background-size: 15px 15px;
        }
    }

    &.validation-error {
        color: ${props => props.theme.colors.danger};

        a {
            color: ${props => props.theme.colors.danger};
        }

        &:before {
            border: 1px solid ${props => props.theme.colors.danger};
        }
    }
`;

interface Props {
    columnCount?: number;
    name: string;
    label?: string;
    required?: boolean;
    rounded?: boolean;
    options: {
        value: string;
        label: string;
    }[];
}

const InputCheckbox: FunctionComponent<Props> = (props) => {
    const [field, meta, helpers] = useField(props.name);
    return (
        <FormGroup className={meta.error && "validation-error"}>
            {props.label && <Label required={props.required}>{props.label}</Label>}
            <div style={{columnCount: props.columnCount || 1}}>
            {props.options.map((option, i) => (
                <Checkbox key={option.value} name={props.name} value={option.value} label={option.label}
                          helpers={helpers} field={field}/>
            ))}
            </div>
        </FormGroup>
    );
};

InputCheckbox.defaultProps = {
    required: false,
};

interface PropsCb {
    name: string;
    value: string;
    label: string;
    helpers: any;
    field: any;
}

const Checkbox: FunctionComponent<PropsCb> = (props) => {
    const [isChecked, setIsChecked] = useState<boolean>(Boolean(props.field.value && props.field.value.find((v: string) => v == props.value)));

    useEffect(() => {
        setIsChecked(Boolean(props.field.value && props.field.value.find((v: string) => v == props.value)));
    }, [props.field.value]);

    return <CheckboxLabel key={props.value} htmlFor={props.name + '-' + props.value}>
        <Input
            type="checkbox"
            name={props.name || ''}
            id={props.name + '-' + props.value}
            checked={isChecked}
            value={props.value || ''}
            onChange={async (e) => {
                let newCheckValue = !isChecked;
                setIsChecked(newCheckValue)
                if (!newCheckValue) {
                    await props.helpers.setValue(props.field.value.filter((v: string) => v != props.value));
                } else {
                    await props.helpers.setValue([...props.field.value, props.value]);
                }
            }}
        />
        <Div className={isChecked ? 'checked' : ''} rounded={false}>
            <div>{props.label}</div>
        </Div>
    </CheckboxLabel>
}

export default InputCheckbox;
