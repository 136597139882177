import { PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import Api from "../api/Api";
import Building from "../api/entities/Building";

export interface BruczekBuildingStatus {
  label: string;
  show: string;
}

interface State {
  current_page: number;
  next_page: number;
  last_page: number | undefined;
  data: Building[] | undefined;
  total: number;
  parameters?: string;
  filtersInfo: boolean;
  buildingCreateInfo: boolean;
  buildingUpdateInfo: boolean;
  buildingDuplicateInfo: boolean;
  statuses: BruczekBuildingStatus[];
}

const initialState: State = {
  current_page: 0,
  next_page: 0,
  last_page: 0,
  total: 0,
  data: [],
  parameters: "",
  filtersInfo: false,
  buildingCreateInfo: false,
  buildingUpdateInfo: false,
  buildingDuplicateInfo: false,
  statuses: [],
};

const client = new Api();

export const fetchBuildingInfinite = createAsyncThunk(
  "building/infinite",
  async (page: number, { getState }) => {
    const state: any = getState();

    const params = () => {
      if (state.building.parameters) {
        return { ...state.building.parameters, page: page };
      }
      return { page: page };
    };

    const response = await client.Building.List(params());
    return {
      data: [...state.building.data, ...response.data.data],
      current_page: response.data.current_page,
      next_page: response.data.current_page + 1,
      statuses: response.data.statuses,
    };
  }
);

export const fetchBuildingSearch = createAsyncThunk("building/search", async (parameters: any) => {
  const response = await client.Building.Search(parameters);

  return { ...response.data, parameters };
});

export const fetchBuilding = createAsyncThunk("building", async () => {
  const response = await client.Building.List();
  return response.data;
});

export const buildingSlice = createSlice({
  name: "building",
  initialState,
  reducers: {
    setFiltersInfo: (state, action) => {
      state.filtersInfo = action.payload;
    },
    showBuildingCreateInfo: (state, action) => {
      state.buildingCreateInfo = action.payload;
    },
    showBuildingUpdateInfo: (state, action) => {
      state.buildingUpdateInfo = action.payload;
    },
    showBuildingDuplicateInfo: (state, action) => {
      state.buildingDuplicateInfo = action.payload;
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(fetchBuilding.fulfilled, (state: State, action: PayloadAction<any>) => {
      state.data = action.payload.data;
      state.last_page = action.payload.last_page;
      state.current_page = action.payload.current_page;
      state.next_page = action.payload.current_page + 1;
      state.total = action.payload.total;
      state.statuses = action.payload.statuses;
    });
    builder.addCase(fetchBuildingSearch.fulfilled, (state: State, action: PayloadAction<any>) => {
      state.data = action.payload.data;
      state.last_page = action.payload.last_page;
      state.next_page = action.payload.current_page + 1;
      state.parameters = action.payload.parameters;
      state.statuses = action.payload.statuses;
    });
    builder.addCase(fetchBuildingInfinite.fulfilled, (state: State, action: PayloadAction<any>) => {
      state.data = action.payload.data;
      state.current_page = action.payload.current_page;
      state.next_page = action.payload.current_page + 1;
      state.statuses = action.payload.statuses;
    });
  },
});

export const {
  setFiltersInfo,
  showBuildingCreateInfo,
  showBuildingUpdateInfo,
  showBuildingDuplicateInfo,
} = buildingSlice.actions;

export default buildingSlice.reducer;
