import axios             from "axios";
import Scope             from "../entities/Scope";
import Entrypoint        from "../Entrypoint";
import {ResponsePromise} from "../ResponsePromise";
import Producer from "../entities/Producer";


class ProducerApi {
  Get(): ResponsePromise<Producer[]> {
    return axios.get(Entrypoint.Api.Producer.Index);
  }
}

export default ProducerApi;
