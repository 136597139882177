import {PayloadAction}    from "@reduxjs/toolkit";
import {createSlice}      from "@reduxjs/toolkit";
import {createAsyncThunk} from "@reduxjs/toolkit";
import Api                from "../api/Api";
import User               from "../api/entities/User";


interface UserState {
  user: User | null;
}

const initialState: UserState = {
  user: null,
};

export const fetchUser = createAsyncThunk("user", async () => {
  const client   = new Api();
  const response = await client.User.Get();

  return response.data;
});

export const newUserSlice = createSlice({
  name:          "user",
  initialState,
  reducers:      {},
  extraReducers: (builder: any) => {
    builder.addCase(fetchUser.fulfilled, (state: UserState, action: PayloadAction<any>) => {
      state.user = action.payload;
    });
  },
});

export default newUserSlice.reducer;

