import React, {FunctionComponent} from 'react';
import styled from 'styled-components';
import {STATUS_TYPE} from '../../types/StatusType';


const StatusWrapper = styled.span`
    font-size: ${props => props.theme.fonts.mobile.xs};
    color: ${props => props.theme.colors.primary};
    padding: 5px 16px;
    border-radius: 4px;
    background-color: ${props => props.theme.colors.primaryLight};
    position: relative;
    overflow: hidden;
    box-shadow: ${({theme}) => theme.shadow.primary};
    white-space: nowrap;

    &:not(:last-child) {
        margin-right: 6px;
    }

    &.status-accepted {
        color: ${props => props.theme.colors.success};
        background-color: ${props => props.theme.colors.successLight};
        box-shadow: ${({theme}) => theme.shadow.success};
    }

    &.status-reported {
        color: ${props => props.theme.colors.warning};
        background-color: ${props => props.theme.colors.warningLight};
        box-shadow: ${({theme}) => theme.shadow.reported};
    }

    &.status-started {
        color: ${props => props.theme.colors.started};
        background-color: ${props => props.theme.colors.startedLight};
        box-shadow: ${({theme}) => theme.shadow.started};
    }

    &.status-finished {
        color: ${props => props.theme.colors.gray};
        background-color: ${props => props.theme.colors.grayLight};
        box-shadow: ${({theme}) => theme.shadow.gray};
    }

    &.status-offered {
        color: ${props => props.theme.colors.offered};
        background-color: ${props => props.theme.colors.offeredLight};
        box-shadow: ${({theme}) => theme.shadow.offered};
    }
    
    &.business-status {
        color: ${props => props.theme.colors.secondary};
        background-color: ${props => props.theme.colors.successLight};
        box-shadow: ${({theme}) => theme.shadow.secondary};
    }
`;

interface StatusProps {
    status: string | null;
    type?: string;
}

const renderStatus = (status: string | null, type?: string) => {
    if (type === 'business') {
        return <StatusWrapper className={'business-status'}>{status}</StatusWrapper>
    }

    switch (status) {
        case (STATUS_TYPE.ACCEPTED):
            return <StatusWrapper className="status-accepted">{status}</StatusWrapper>;
        case(STATUS_TYPE.REPORTED):
            return <StatusWrapper className="status-reported">{status}</StatusWrapper>;
        case(STATUS_TYPE.STARTED):
            return <StatusWrapper className="status-started">{status}</StatusWrapper>;
        case(STATUS_TYPE.FINISHED):
            return <StatusWrapper className="status-finished">{status}</StatusWrapper>;
        case(STATUS_TYPE.OFFERED):
            return <StatusWrapper className="status-offered">{status}</StatusWrapper>;
        default:
            return <StatusWrapper>{status}</StatusWrapper>;
    }
};

const BuildingStatus: FunctionComponent<StatusProps> = ({status, type}) => renderStatus(status, type);

export default BuildingStatus;
