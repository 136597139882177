import axios from "axios";
import Entrypoint from "../Entrypoint";
import UserCreateRequest from "../request/user/UserCreateRequest";
import UserEditRequest from "../request/user/UserEditRequest";
import UserFillRequest from "../request/user/UserFillRequest";


class UserApi {

  Get() {
    return axios.get(Entrypoint.Api.User.Show);
  }

  Fill(user: UserFillRequest) {
    return axios.patch(Entrypoint.Api.User.Fill, user);
  }

  Store(user: UserCreateRequest) {
    return axios.post(Entrypoint.Api.User.Store, user);
  }

  Update(user: UserEditRequest) {
    return axios.post(Entrypoint.Api.User.Update, user);
  }
}

export default UserApi;
