import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { matchRoutes } from 'react-router';
import { Link, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import Building from '../../api/entities/Building';
import EnvelopeIcon from '../../assets/icons/envelope.svg';
import PhoneIcon from '../../assets/icons/phone.svg';
import BuildingStatus from '../../components/building-status/BuildingStatus';
import ButtonPrimaryForward from '../../components/buttons/ButtonPrimaryForward';
import { defaultModalCloseStyles } from '../../components/modal/defaultModalStyles';
import Header from '../../components/typography/Header';
import Paragraph from '../../components/typography/Paragraph';
import Wrapper from '../../components/wrapper/Wrapper';
import useApi from '../../hooks/useApi';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import useHtmlParser from '../../hooks/useHtmlParser';
import { ROUTE_BUILDING_SHOW, ROUTE_HOME } from '../../routing/routes';
import { showBuildingForm } from '../../store/BuildingForm';
import { showStartModal, showFinishModal, showOfferedBuildingModal } from '../../store/ModalBuildingReducer';
import { showSubpage } from '../../store/SubpageReducer';
import { useAppSelector } from '../../hooks/useAppSelector';


const CardStatusWrapper = styled.div`
    display: flex;
    margin-bottom: 24px;

    & > * {
        &:not(:last-child) {
            margin-right: 8px
        }
    }
`;

const BuildingDetailsPart = styled.div`
    &:not(:last-child) {
        margin-bottom: 24px;
        padding-bottom: 24px;
        border-bottom: 1px solid #B6D3FF
    }
`;

const StyledBuildingDetailsPart = styled(BuildingDetailsPart)`
    text-align: center;
`;

const TextLeft = styled(Paragraph)`
    margin-right: 8px;
    font-weight: ${({ theme }) => theme.weights.medium};
    white-space: nowrap;
`;

const TextRight = styled(Paragraph)`
    text-align: right;

    ol, ul {
        margin: 8px 0;

        li {
            &:not(:last-child) {
                margin-bottom: 4px;
            }
        }
    }

    ol {
        padding-left: 10px;

        li {
            padding-left: 2px;
        }
    }

    ul {
        li {
            position: relative;
            padding-left: 10px;

            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 7px;
                left: 0;
                width: 4px;
                height: 4px;
                background-color: ${({ theme }) => theme.colors.primary};
                border-radius: 50%;

            }
        }
    }

    a {
        text-decoration: none !important;
        display: inline-block;

        img {
            width: 20px;
            height: 10px;
            object-fit: contain;
            margin-top: 8px;
        }
    }
`;

const BuildingDetailsPartItem = styled.div`
    display: flex;
    justify-content: space-between;

    &.flex-column {
        flex-direction: column;

        ${TextRight} {
            text-align: left;
        }
    }
`;

const StyledHeader = styled(Header)`
    width: 100%;
    font-size: ${props => props.theme.fonts.mobile.md};
    font-weight: ${props => props.theme.weights.bold};
    text-align: left;
    margin-bottom: 8px;
`;

const StyledWrapper = styled(Wrapper)`
    border-radius: 5px;
    padding: 24px 24px 124px 24px;
    margin-bottom: 48px;
    position: relative;
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet_small}) {
        margin: 0 auto;
    }
`;

const CloseButton = styled(Link)`
    display: none;
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        ${defaultModalCloseStyles};
        display: block;
    }
`;

const StyledParagraph = styled(Paragraph)`
    line-height: 1.6;

    b {
        font-weight: 500;
        margin-bottom: 2px;
        display: block;
    }
`;

const PageHeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 24px;
`;

const BuildingEditButton = styled.button`
    min-width: 90px;
    border-radius: 30px;
    background-color: ${({ theme }) => theme.colors.primary};
    color: #fff;
    text-align: center;
    padding: 6px 8px;
    font-size: 12px;
    font-weight: 600;
    flex-shrink: 0;
    cursor: pointer;
    border: none;
    white-space: nowrap;

    &:hover {
    }
`;

const BuildingOfferButton = styled(BuildingEditButton)`
    padding: 6px 18px;
`;

const BuildingOfferWrapper = styled.div`
    margin-top: 8px;
`;

// const BuildingMapWrapper = styled.div`
//     height: 200px;
// `;

type RouteParameters = {
  id?: string;
}

const BuildingShowPage = () => {
  const Api = useApi();
  const { id } = useParams<RouteParameters>();
  const [data, setData] = useState<Building>();
  const location = useLocation();
  const match = matchRoutes([{ path: ROUTE_BUILDING_SHOW }], location.pathname);
  const dispatch = useAppDispatch();
  const user = useAppSelector(state => state.newUser.user);
  let subpage = location.state as { subpage: boolean | undefined };


  useEffect(() => {
    if (id) {
      Api.Building.Get(id).then(({ data }) => {
        setData(data);
        if (match || subpage) {
          dispatch(showSubpage());
        }
      });
    }
  }, []);

  return (
    <>
      <StyledWrapper>
        {
          data && (
            <>
              <CardStatusWrapper>
                <BuildingStatus status={data.paver_status}/>
                <BuildingStatus type={'business'} status={data.business_status}/>
                {data.is_offered_confirmed && (<BuildingStatus status={data.is_offered ? 'Do oddania' : null}/>)}
              </CardStatusWrapper>
              <PageHeaderWrapper>
                <StyledHeader>{data.title}</StyledHeader>
                <CloseButton to={ROUTE_HOME}/>
                <BuildingEditButton onClick={() => dispatch(showBuildingForm(data.id))}>Edytuj</BuildingEditButton>
              </PageHeaderWrapper>
              {
                data.is_offered && !data.is_offered_confirmed && (
                  <BuildingDetailsPart>
                    <Paragraph><b>Uwaga!</b></Paragraph>
                    <Paragraph>Ta budowa została zgłoszona do oddania i oczekuje na weryfikację prez administratora.</Paragraph>
                  </BuildingDetailsPart>
                )
              }
              {
                (data.firstname || data.lastname || data.street || data.city) && (
                  <BuildingDetailsPart>
                    {(data.firstname || data.lastname) && (<StyledParagraph>{data.firstname} {data.lastname}</StyledParagraph>)}
                    {data.street && <StyledParagraph>ul.{data.street} {data.building_nr}{data.apartment_nr && <>/{data.apartment_nr}</>}</StyledParagraph>}
                    {data.city && <StyledParagraph>{data.postcode} {data.city}</StyledParagraph>}
                  </BuildingDetailsPart>
                )
              }
              {/*{data.city && (*/}
              {/*  <BuildingDetailsPart>*/}
              {/*    <BuildingMapWrapper>*/}
              {/*      /!*<GoogleMapComponent address={`${data.street} ${data.building_nr}, ${data.city}`}/>*!/*/}
              {/*      /!*<GoogleMapComponent buildings={[]}/>*!/*/}
              {/*    </BuildingMapWrapper>*/}
              {/*  </BuildingDetailsPart>*/}
              {/*)}*/}
              {
                (data.construction_nr || data.project_nr) && (
                  <BuildingDetailsPart>
                    {
                      data.construction_nr && (
                        <BuildingDetailsPartItem>
                          <TextLeft>Numer budowy:</TextLeft>
                          <TextRight><b>{data.construction_nr}</b></TextRight>
                        </BuildingDetailsPartItem>
                      )
                    }
                    {
                      data.project_nr && (
                        <BuildingDetailsPartItem>
                          <TextLeft>Numer projektu:</TextLeft>
                          <TextRight>{data.project_nr}</TextRight>
                        </BuildingDetailsPartItem>
                      )
                    }
                    {
                      data.project && (
                        <BuildingDetailsPartItem>
                          <TextLeft>Projekt w google</TextLeft>
                          <TextRight>
                            <a href={data.project} target="_blank">Otwórz projekt</a>
                          </TextRight>
                        </BuildingDetailsPartItem>
                      )
                    }
                  </BuildingDetailsPart>
                )
              }
              {
                (data.phone || data.email || data.square_meter || data.running_meter || data.scopes || data.user_source) && (
                  <BuildingDetailsPart>
                    {
                      data.phone && (
                        <BuildingDetailsPartItem>
                          <TextLeft>Telefon:</TextLeft>
                          <TextRight className="with-icon">
                            <a href={`tel:${data.phone}`}><img src={PhoneIcon} alt=""/>{data.phone}</a>
                          </TextRight>
                        </BuildingDetailsPartItem>
                      )
                    }
                    {
                      data.email && (
                        <BuildingDetailsPartItem>
                          <TextLeft>Adres email:</TextLeft>
                          <TextRight className="with-icon">
                            <a href={`mailto:${data.email}`}><img src={EnvelopeIcon} alt=""/>{data.email}</a>
                          </TextRight>
                        </BuildingDetailsPartItem>
                      )
                    }
                    {data.square_meter && (
                      <BuildingDetailsPartItem>
                        <TextLeft>Szacunkowa ilość kostki:</TextLeft>
                        <TextRight>{data.square_meter}m<sup>2</sup></TextRight>
                      </BuildingDetailsPartItem>

                    )}
                    {data.running_meter && (
                      <BuildingDetailsPartItem>
                        <TextLeft>Szacunkowe długość ogrodzenia:</TextLeft>
                        <TextRight>{data.running_meter}m.b.</TextRight>
                      </BuildingDetailsPartItem>
                    )}
                    {
                      data.scopes && data.scopes.length > 0 && (
                        <BuildingDetailsPartItem className={data.scopes.length > 4 ? 'flex-column' : ''}>
                          <TextLeft>Zakres prac:</TextLeft>
                          <TextRight>
                            {
                              data.scopes.map((scope: any, i) => (
                                  <span key={i}>{scope.name}{i < data.scopes.length - 1 ? ', ' : <>&nbsp;</>}</span>
                                ),
                              )
                            }
                          </TextRight>
                        </BuildingDetailsPartItem>
                      )
                    }
                    {
                        data.producers && data.producers.length > 0 && (
                            <BuildingDetailsPartItem className={data.scopes.length > 4 ? 'flex-column' : ''}>
                              <TextLeft>Producent:</TextLeft>
                              <TextRight>
                                {
                                  data.producers.map((producer: any, i) => (
                                          <span key={i}>{producer.name}{i < data.scopes.length - 1 ? ', ' : <>&nbsp;</>}</span>
                                      ),
                                  )
                                }
                              </TextRight>
                            </BuildingDetailsPartItem>
                        )
                    }
                  </BuildingDetailsPart>
                )
              }
              {
                (data.start_date || data.end_date) && (
                  <BuildingDetailsPart>
                    {
                      data.start_date && (
                        <BuildingDetailsPartItem>
                          <TextLeft>Data rozpoczęcia</TextLeft>
                          <TextRight>{moment(data.start_date).format('DD.MM.YYYY')}r</TextRight>
                        </BuildingDetailsPartItem>
                      )
                    }
                    {
                      data.end_date && (
                        <BuildingDetailsPartItem>
                          <TextLeft>Data zakończenia:</TextLeft>
                          <TextRight>{moment(data.end_date).format('DD.MM.YYYY')}r</TextRight>
                        </BuildingDetailsPartItem>
                      )
                    }
                  </BuildingDetailsPart>
                )
              }
              {
                data.note && (
                  <BuildingDetailsPart>
                    <BuildingDetailsPartItem className="flex-column">
                      <TextLeft>Informacje dodatkowe:</TextLeft>
                      <TextRight>
                        {/* eslint-disable-next-line react-hooks/rules-of-hooks */}
                        {useHtmlParser(data.note)}
                      </TextRight>
                    </BuildingDetailsPartItem>
                  </BuildingDetailsPart>
                )
              }
              {
                data.note_designer && (
                  <BuildingDetailsPart>
                    <BuildingDetailsPartItem className="flex-column">
                      <TextLeft>Notatka prywatna:</TextLeft>
                      <TextRight>
                        {/* eslint-disable-next-line react-hooks/rules-of-hooks */}
                        {useHtmlParser(data.note_designer)}
                      </TextRight>
                    </BuildingDetailsPartItem>
                  </BuildingDetailsPart>
                )
              }
              {
                data.note_paver && (
                  <BuildingDetailsPart>
                    <BuildingDetailsPartItem className="flex-column">
                      <TextLeft>Notatka prywatna:</TextLeft>
                      <TextRight>
                        {/* eslint-disable-next-line react-hooks/rules-of-hooks */}
                        {useHtmlParser(data.note_paver)}
                      </TextRight>
                    </BuildingDetailsPartItem>
                  </BuildingDetailsPart>
                )
              }
              {
                !data.is_offered && (
                  <BuildingDetailsPart>
                    <BuildingDetailsPartItem className="flex-column">
                      <TextLeft>Jeżeli chcesz oddać swoją budowę, kliknij poniższy przycisk:</TextLeft>
                      <div>
                        <BuildingOfferWrapper>
                          <BuildingOfferButton type="button" onClick={() => {
                            if (data.id) {
                              Api.Building.OfferEdit(data.id)
                                .then(() => {
                                  dispatch(showOfferedBuildingModal());
                                })
                                .catch(err => console.error(err));
                            }
                          }}>Oddaj budowę</BuildingOfferButton>
                        </BuildingOfferWrapper>
                      </div>
                    </BuildingDetailsPartItem>
                  </BuildingDetailsPart>
                )
              }
              {
                user && user.type === 'Paver' && (
                  <>
                    {
                      data.paver_status === 'Zaakceptowana' && (
                        <StyledBuildingDetailsPart>
                          <ButtonPrimaryForward onClick={() => dispatch(showStartModal(id))}>Rozpocznij budowę</ButtonPrimaryForward>
                        </StyledBuildingDetailsPart>
                      )
                    }
                    {
                      data.paver_status === 'Rozpoczęta' && (
                        <StyledBuildingDetailsPart>
                          <ButtonPrimaryForward onClick={() => dispatch(showFinishModal(id))}>Zakończ budowę</ButtonPrimaryForward>
                        </StyledBuildingDetailsPart>
                      )
                    }
                  </>
                )
              }
            </>
          )
        }
      </StyledWrapper>
    </>
  );
};

export default BuildingShowPage;
